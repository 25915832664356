import React, { useState } from "react";
import {
  Button,
  FileInput,
  Group,
  Image,
  Loader,
  Modal,
  Text,
  TextInput,
} from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";
import { notifications } from "@mantine/notifications";
import styled from "styled-components";
import { IconTrash } from "@tabler/icons-react";

import { useAdmin } from "../hooks/use-admin";
import { UPDATE_ADMIN_PASSWORD } from "../gqls/admin";
import { BANNERS, UPDATE_BANNER } from "../gqls/banner";

export const HomePage = () => {
  const { admin } = useAdmin();

  const { data: bannerData, loading: bannerLoading } = useQuery(BANNERS, {
    variables: {
      where: { type: "main" },
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const defaultBanner = data?.banners?.[0];
      if (defaultBanner) {
        setBannerTitle(defaultBanner.title);
        setBannerSubtitle(defaultBanner.subtitle ?? "");
        if (defaultBanner?.background) {
          setBannerDefaultBackground(defaultBanner.background);
        }
      }
    },
  });

  const [updateAdminPassword, { loading }] = useMutation(
    UPDATE_ADMIN_PASSWORD,
    {
      onCompleted: () => {
        setVisibleUpdatePassword(false);
        notifications.show({
          color: "green",
          title: "Пароль изменен",
        });
      },
      onError: (err) => {
        console.error(err);
        notifications.show({
          color: "red",
          title:
            err.message === "password-error"
              ? "Неправильный текущий пароль"
              : "Произошла ошибка, повторите еще раз",
        });
      },
    }
  );

  const [updateBanner] = useMutation(UPDATE_BANNER, {
    onCompleted: () => {
      setVisibleUpdateBanner(false);
      notifications.show({
        color: "green",
        title: "Баннер изменен",
      });
    },
    onError: (err) => {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
    },
  });

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [bannerTitle, setBannerTitle] = useState("");
  const [bannerSubtitle, setBannerSubtitle] = useState("");
  const [file, setFile] = useState(null);
  const [bannerUpdateLoading, setBannerUpdateLoading] = useState(false);
  const [bannerDefaultBackground, setBannerDefaultBackground] = useState(false);

  const [visibleUpdatePassword, setVisibleUpdatePassword] = useState(false);
  const [visibleUpdateBanner, setVisibleUpdateBanner] = useState(false);

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (newPassword !== repeatPassword) {
      notifications.show({
        color: "yellow",
        title: "Пароли не совпадают",
      });
      return;
    }
    updateAdminPassword({
      variables: { data: { currentPassword, newPassword } },
    });
  };

  const handleUpdateBanner = async (e) => {
    e.preventDefault();
    setBannerUpdateLoading(true);
    let bannerBackground;
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const photoResponse = await fetch("/api/admin/upload", {
        method: "POST",
        body: formData,
        headers: { authorization: localStorage.getItem("accessToken") },
      }).then((res) => res.json());
      if (!photoResponse?.filename) {
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
        setBannerUpdateLoading(false);
        return;
      }
      bannerBackground = photoResponse.filename;
    }
    updateBanner({
      variables: {
        data: {
          title: bannerTitle,
          subtitle: bannerSubtitle,
          background:
            bannerDefaultBackground === null ? null : bannerBackground,
        },
        where: { id: mainBanner.id },
      },
    }).finally(() => {
      setBannerUpdateLoading(false);
    });
  };

  const mainBanner = bannerData?.banners?.[0];

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Профиль</Text>
      </Group>
      <Group mt={12}>
        <Text size="sm">Email:</Text>
        <Text size="sm" fw="600">
          {admin.email}
        </Text>
      </Group>
      <div>
        <Button
          onClick={() => setVisibleUpdatePassword(true)}
          variant="outline"
          mt={16}
          size="xs"
        >
          Изменить пароль
        </Button>
      </div>
      <Group mt={20} align="center" justify="space-between">
        <Text fw="600">Баннер</Text>
      </Group>
      {bannerLoading ? (
        <Loader size="sm" mt={16} />
      ) : (
        <>
          <Banner background={mainBanner?.background}>
            <div className="title">{mainBanner?.title}</div>
            <div className="description">{mainBanner?.subtitle}</div>
          </Banner>
          <div>
            <Button
              onClick={() => setVisibleUpdateBanner(true)}
              variant="outline"
              mt={16}
              size="xs"
            >
              Изменить баннер
            </Button>
          </div>
        </>
      )}
      <Modal
        title="Изменить пароль"
        opened={visibleUpdatePassword}
        onClose={() => setVisibleUpdatePassword(false)}
      >
        <form onSubmit={handleUpdatePassword}>
          <TextInput
            type="password"
            required
            label="Текущий пароль"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextInput
            type="password"
            required
            label="Новый пароль"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            mt={16}
          />
          <TextInput
            type="password"
            required
            label="Повторите пароль"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            mt={16}
          />
          <Button loading={loading} type="submit" mt={20}>
            Изменить
          </Button>
        </form>
      </Modal>
      <Modal
        title="Изменить баннер"
        opened={visibleUpdateBanner}
        onClose={() => setVisibleUpdateBanner(false)}
      >
        <form onSubmit={handleUpdateBanner}>
          <TextInput
            required
            label="Заголовок"
            value={bannerTitle}
            onChange={(e) => setBannerTitle(e.target.value)}
          />
          <TextInput
            required
            label="Подзаголовок"
            value={bannerSubtitle}
            onChange={(e) => setBannerSubtitle(e.target.value)}
            mt={16}
          />
          <FileInput
            label="Новый задний фон"
            placeholder="Нажмите, чтобы выбрать изображение"
            mt={16}
            value={file}
            onChange={setFile}
          />
          {!file && bannerDefaultBackground ? (
            <ImageContainer>
              <Image
                src={`/uploads/${bannerDefaultBackground}`}
                alt={bannerDefaultBackground}
                mt={16}
                width={200}
              />
              <div
                className="delete"
                onClick={() => {
                  setFile(null);
                  setBannerDefaultBackground(null);
                }}
              >
                <IconTrash color="red" />
              </div>
            </ImageContainer>
          ) : null}
          <Button loading={bannerUpdateLoading} type="submit" mt={20}>
            Изменить
          </Button>
        </form>
      </Modal>
    </>
  );
};

const Banner = styled.div`
  width: 80%;
  height: 320px;
  background-color: #f5f0e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 12px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${(props) =>
    props.background
      ? `
        background-image: url('/uploads/${props.background}');
      `
      : ""}

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (max-width: 650px) {
    grid-area: 1 / 1 / 2 / 3;
    height: 200px;
  }

  @media only screen and (max-width: 400px) {
    height: 150px;
  }

  .title {
    font-size: 30px;
    color: #232323;
    font-weight: 600;
    width: 100%;
    line-height: 30px;

    @media only screen and (max-width: 800px) {
      font-size: 26px;
    }

    @media only screen and (max-width: 500px) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .description {
    font-size: 16px;
    color: #626262;
    font-weight: 500;
    margin-top: 12px;
    width: 100%;

    @media only screen and (max-width: 800px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 500px) {
      margin-top: 6px;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: 0.3s opacity;

    &:hover {
      opacity: 0.8;
    }
  }
`;
