import { gql } from "@apollo/client";

export const ADMIN = gql`
  query ADMIN {
    admin {
      id
      email
    }
  }
`;

export const LOGIN_ADMIN = gql`
  mutation LOGIN_ADMIN($data: LoginAdminInput!) {
    loginAdmin(data: $data)
  }
`;

export const UPDATE_ADMIN_PASSWORD = gql`
  mutation UPDATE_ADMIN_PASSWORD($data: UpdateAdminPasswordInput!) {
    updateAdminPassword(data: $data) {
      id
      email
    }
  }
`;
