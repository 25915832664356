import { gql } from "@apollo/client";

const REVIEW_FRAGMENT = gql`
  fragment ReviewReturn on Review {
    id
    createdAt
    updatedAt
    name
    nomenclatureIndexNumber
    video
    image
  }
`;

export const REVIEW = gql`
  ${REVIEW_FRAGMENT}
  query REVIEW($where: ReviewWhereInput!) {
    review(where: $where) {
      ...ReviewReturn
    }
  }
`;

export const REVIEWS = gql`
  ${REVIEW_FRAGMENT}
  query REVIEWS($where: ReviewsWhereInput, $skip: Int, $take: Int) {
    reviews(where: $where, skip: $skip, take: $take) {
      ...ReviewReturn
    }
    reviewsCount(where: $where)
  }
`;

export const CREATE_REVIEW = gql`
  ${REVIEW_FRAGMENT}
  mutation CREATE_REVIEW($data: CreateReviewInput!) {
    createReview(data: $data) {
      ...ReviewReturn
    }
  }
`;

export const UPDATE_REVIEW = gql`
  ${REVIEW_FRAGMENT}
  mutation UPDATE_REVIEW($where: ReviewWhereInput!, $data: UpdateReviewInput!) {
    updateReview(where: $where, data: $data) {
      ...ReviewReturn
    }
  }
`;

export const DELETE_REVIEW = gql`
  ${REVIEW_FRAGMENT}
  mutation DELETE_REVIEW($where: ReviewWhereInput!) {
    deleteReview(where: $where) {
      ...ReviewReturn
    }
  }
`;
