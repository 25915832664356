import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Group,
  LoadingOverlay,
  Pagination,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { ORDERS } from "../../gqls/order";

const TAKE = 30;

export const getOrderStatus = (status) => {
  if (status === "new") {
    return (
      <Badge size="xs" color="blue">
        Новый
      </Badge>
    );
  }
  if (status === "processing") {
    return (
      <Badge size="xs" color="yellow">
        В обработке
      </Badge>
    );
  }
  if (status === "done") {
    return (
      <Badge size="xs" color="green">
        Выполнен
      </Badge>
    );
  }
  if (status === "cancelled") {
    return (
      <Badge size="xs" color="red">
        Отменен
      </Badge>
    );
  }
  return null;
};

export const OrdersPage = () => {
  const [skip, setSkip] = useState(0);

  const [filterStatus, setFilterStatus] = useState();
  const [filterNumber, setFilterNumber] = useState();
  const [filterPaymentType, setFilterPaymentType] = useState();

  const [number, setNumber] = useState("");

  const { data, loading } = useQuery(ORDERS, {
    fetchPolicy: "network-only",
    variables: {
      skip,
      take: TAKE,
      where: {
        status: filterStatus,
        number: filterNumber,
        paymentType: filterPaymentType,
      },
    },
  });

  useEffect(() => {
    let timeout = setTimeout(() => {
      setFilterNumber(number ? number : undefined);
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [number]);

  const orders = data?.orders || [];
  const totalCount = data?.ordersCount || 0;

  const pagesCount = Math.ceil(totalCount / TAKE);
  const currentPage = skip / TAKE + 1;

  const rows = orders.map((element) => (
    <Table.Tr key={element.id}>
      <Table.Td>
        <Link className="link" to={`/orders/${element.id}`}>
          {element.number}
        </Link>
      </Table.Td>
      <Table.Td>{element.userPhone}</Table.Td>
      <Table.Td>{element.userEmail}</Table.Td>
      <Table.Td>{element.totalPrice}</Table.Td>
      <Table.Td>
        {element.paymentType === "upon-receive" ? "При получении" : "Онлайн"}
      </Table.Td>
      <Table.Td>{getOrderStatus(element.status)}</Table.Td>
      <Table.Td>{dayjs(element.createdAt).format("DD.MM.YYYY HH:mm")}</Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Заказы</Text>
      </Group>
      <Content>
        <Filter>
          <Select
            label="Статус"
            className="filter"
            data={[
              { value: "new", label: "Новый" },
              { value: "processing", label: "В обработке" },
              { value: "done", label: "Выполнен" },
              { value: "cancelled", label: "Отменен" },
            ]}
            size="xs"
            value={filterStatus}
            onChange={(v) => setFilterStatus(v ? v : undefined)}
            allowDeselect
            clearable
          />
          <Select
            label="Оплата"
            className="filter"
            data={[
              { value: "upon-receive", label: "При получении" },
              { value: "online", label: "Онлайн" },
            ]}
            size="xs"
            value={filterPaymentType}
            onChange={(v) => setFilterPaymentType(v ? v : undefined)}
            allowDeselect
            clearable
          />
          <TextInput
            label="Номер заказа"
            className="filter"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            size="xs"
          />
        </Filter>
        <Box pos="relative">
          <LoadingOverlay
            visible={loading}
            zIndex={1000}
            loaderProps={{ size: "sm" }}
            overlayProps={{ radius: "sm", blur: 2 }}
          />
          <TableContainer>
            <Table mt={8} w={1000} pb={6}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>№</Table.Th>
                  <Table.Th>Телефон</Table.Th>
                  <Table.Th>Email</Table.Th>
                  <Table.Th>Сумма (₽)</Table.Th>
                  <Table.Th>Оплата</Table.Th>
                  <Table.Th>Статус</Table.Th>
                  <Table.Th>Дата</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </TableContainer>
        </Box>
        <Pagination
          onChange={(v) => setSkip((v - 1) * TAKE)}
          value={currentPage}
          size="sm"
          mt={16}
          total={pagesCount}
        />
      </Content>
    </>
  );
};

const Content = styled.div`
  .link {
    color: #228be6;
    text-decoration: underline;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  max-width: 780px;
  padding-bottom: 16px;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 6px;

  .filter {
    width: 200px;
    margin-bottom: 6px;
    margin-right: 6px;
  }
`;
