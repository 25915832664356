import { useQuery } from "@apollo/client";

import { ADMIN } from "../gqls/admin";

export const useAdmin = () => {
  const { data, loading } = useQuery(ADMIN);

  const admin = data?.admin;

  return { admin, loading };
};
