import React, { useState } from "react";
import {
  Card,
  Center,
  Grid,
  Group,
  Image,
  Loader,
  Pagination,
  Text,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { IconPlus } from "@tabler/icons-react";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

import { REVIEWS } from "../../gqls/review";

const TAKE = 24;

export const ReviewsPage = () => {
  const [skip, setSkip] = useState(0);

  const navigate = useNavigate();

  const { data, loading } = useQuery(REVIEWS, {
    fetchPolicy: "network-only",
    variables: { skip, take: TAKE },
  });

  const reviews = data?.reviews || [];
  const totalCount = data?.reviewsCount || 0;

  const pagesCount = Math.ceil(totalCount / TAKE);
  const currentPage = skip / TAKE + 1;

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Обзоры</Text>
        <Link to="/reviews/create">
          <Group gap={6} align="center">
            <IconPlus color="#228be6" size={16} />
            <Text size="sm" c="blue">
              Добавить
            </Text>
          </Group>
        </Link>
      </Group>
      {loading ? (
        <Center h={100}>
          <Loader size="sm" />
        </Center>
      ) : (
        <>
          <Grid mt={16}>
            {reviews.map((item) => (
              <Grid.Col span={3} key={item.id}>
                <Card
                  withBorder
                  padding="xs"
                  onClick={() => {
                    navigate(`/reviews/${item.id}/update`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Card.Section>
                    <Image src={`/uploads/${item.image}`} alt={item.image} />
                  </Card.Section>
                  <Text size="xs" truncate="end" mt={8}>
                    {item.name}
                  </Text>
                  <Text c="gray" size="xs">
                    {dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Text>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
          <Pagination
            onChange={(v) => setSkip((v - 1) * TAKE)}
            value={currentPage}
            size="sm"
            mt={16}
            total={pagesCount}
          />
        </>
      )}
    </>
  );
};
