import { gql } from "@apollo/client";

export const CITIES = gql`
  query CITIES {
    cities {
      id
      name
      deliveryExist
    }
  }
`;
