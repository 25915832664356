import React, { useState } from "react";
import {
  Center,
  Card,
  Grid,
  Group,
  Image,
  Loader,
  Text,
  Pagination,
  Badge,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";

import { PROMOTIONS } from "../../gqls/promotion";

const TAKE = 24;

export const PromotionsPage = () => {
  const [skip, setSkip] = useState(0);

  const navigate = useNavigate();

  const { data, loading } = useQuery(PROMOTIONS, {
    fetchPolicy: "network-only",
    variables: { skip, take: TAKE },
  });

  const promotions = data?.promotions || [];
  const totalCount = data?.promotionsCount || 0;

  const pagesCount = Math.ceil(totalCount / TAKE);
  const currentPage = skip / TAKE + 1;

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Акции</Text>
        <Link to="/promotions/create">
          <Group gap={6} align="center">
            <IconPlus color="#228be6" size={16} />
            <Text size="sm" c="blue">
              Добавить
            </Text>
          </Group>
        </Link>
      </Group>
      {loading ? (
        <Center h={100}>
          <Loader size="sm" />
        </Center>
      ) : (
        <>
          <Grid mt={16}>
            {promotions.map((item) => (
              <Grid.Col span={3} key={item.id}>
                <Card
                  withBorder
                  padding="xs"
                  onClick={() => navigate(`/promotions/${item.id}`)}
                  style={{ cursor: "pointer" }}
                >
                  <Card.Section>
                    <Image src={`/uploads/${item.image}`} alt={item.image} />
                  </Card.Section>
                  <Text size="xs" truncate="end" mt={8}>
                    {item.title}
                  </Text>
                  <Text c="gray" size="xs">
                    {dayjs(item.createdAt).format("DD.MM.YYYY HH:mm")}
                  </Text>
                  {item.active ? (
                    <Badge mt={8} color="green" size="xs">
                      Действует
                    </Badge>
                  ) : (
                    <Badge mt={8} color="red" size="xs">
                      Не действует
                    </Badge>
                  )}
                </Card>
              </Grid.Col>
            ))}
          </Grid>
          <Pagination
            onChange={(v) => setSkip((v - 1) * TAKE)}
            value={currentPage}
            size="sm"
            mt={16}
            total={pagesCount}
          />
        </>
      )}
    </>
  );
};
