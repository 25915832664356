import { gql } from "@apollo/client";

const ORDER_FRAGMENT = gql`
  fragment OrderReturn on Order {
    id
    createdAt
    updatedAt
    userId
    userPhone
    userEmail
    status
    paymentType
    deliveryType
    pickupSalePointId
    deliveryAddress
    products
    productsPrice
    deliveryPrice
    totalPrice
    number
    paymentStatus
  }
`;

export const ORDER = gql`
  ${ORDER_FRAGMENT}
  query ORDER($where: OrderWhereInput!) {
    order(where: $where) {
      ...OrderReturn
    }
  }
`;

export const ORDERS = gql`
  ${ORDER_FRAGMENT}
  query ORDERS($where: OrdersWhereInput, $skip: Int, $take: Int) {
    orders(where: $where, skip: $skip, take: $take) {
      ...OrderReturn
    }
    ordersCount(where: $where)
  }
`;

export const UPDATE_ORDER = gql`
  ${ORDER_FRAGMENT}
  mutation UPDATE_ORDER($where: OrderWhereInput!, $data: UpdateOrderInput!) {
    updateOrder(where: $where, data: $data) {
      ...OrderReturn
    }
  }
`;
