import React, { useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import {
  Badge,
  Button,
  Center,
  Group,
  Loader,
  Space,
  Text,
} from "@mantine/core";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { ORDER } from "../../gqls/order";
import { getOrderStatus } from "./index";
import { CITIES } from "../../gqls/city";
import { SALE_POINTS } from "../../gqls/sale-point";
import { NOMENCLATURES } from "../../gqls/nomenclature";
import { NomenclatureProduct } from "../week-products/create-modal";
import { UpdateOrderModal } from "./update-modal";

export const priceFormatter = new Intl.NumberFormat("ru-RU", {
  style: "currency",
  currency: "RUB",
});

export const OrderPage = () => {
  const { id } = useParams();

  const [updateVisible, setUpdateVisible] = useState(false);

  const { data, loading } = useQuery(ORDER, { variables: { where: { id } } });
  const { data: citiesData, loading: citiesLoading } = useQuery(CITIES);
  const { data: salePointsData } = useQuery(SALE_POINTS);

  const cities = citiesData?.cities || [];
  const salePoints = salePointsData?.salePoints || [];
  const order = data?.order;

  const { data: nomenclaturesData } = useQuery(NOMENCLATURES, {
    skip: !order,
    variables: {
      where: {
        id: { in: order?.products?.map((item) => item.nomenclatureId) },
      },
    },
  });
  const products = nomenclaturesData?.nomenclatures || [];

  const getDeliveryAddress = (deliveryAddress) => {
    const city = cities.find((item) => item.id === deliveryAddress.city);
    const address = [];
    if (city?.name) address.push(city.name);
    if (deliveryAddress?.street) address.push(deliveryAddress.street);
    if (deliveryAddress?.number) address.push(deliveryAddress.number);
    if (deliveryAddress?.apartment)
      address.push(`кв ${deliveryAddress.apartment}`);
    if (deliveryAddress?.floor) address.push(`этаж ${deliveryAddress.floor}`);
    if (deliveryAddress?.door) address.push(`подъезд ${deliveryAddress.door}`);
    return address.join(", ");
  };

  const salePoint = salePoints.find(
    (item) => item.id === order?.pickupSalePointId
  );

  return (
    <>
      <Group mb={12} align="center" justify="space-between">
        <Text fw="600">Заказ</Text>
        {getOrderStatus(order?.status)}
      </Group>
      {loading || citiesLoading ? (
        <Center h={100}>
          <Loader size="sm" />
        </Center>
      ) : order ? (
        <>
          <Value label="Номер заказа" value={order.number} />
          <Value
            label="Дата создания"
            value={dayjs(order.createdAt).format("DD.MM.YYYY HH:mm")}
          />
          <Value label="Номер телефона" value={order.userPhone} />
          <Value label="Email" value={order.userEmail} />
          <Space h={16} />
          <Value
            label="Получение"
            value={order.deliveryType === "delivery" ? "Доставка" : "Самовывоз"}
          />
          {order.deliveryType === "delivery" ? (
            <Value
              label="Адрес доставки"
              value={getDeliveryAddress(order.deliveryAddress)}
            />
          ) : (
            <Value label="Адрес магазина" value={salePoint?.address} />
          )}
          <Space h={16} />
          <Value
            label="Оплата"
            value={
              order.paymentType === "upon-receive" ? "При получении" : "Онлайн"
            }
          />
          <Value
            label="Статус оплаты"
            value={
              order.paymentStatus === "paid" ? (
                <span style={{ color: "green" }}>Оплачен</span>
              ) : (
                <span style={{ color: "darkred" }}>Не оплачен</span>
              )
            }
          />
          <Value
            label="Сумма товаров"
            value={priceFormatter.format(order.productsPrice)}
          />
          <Value
            label="Цена доставки"
            value={priceFormatter.format(order.deliveryPrice)}
          />
          <Value
            label="Итого"
            value={priceFormatter.format(order.totalPrice)}
          />
          <Group>
            <Button
              onClick={() => setUpdateVisible(true)}
              variant="outline"
              size="xs"
              mt={8}
            >
              Изменить статус
            </Button>
          </Group>
          <Text mt={16} fw="600">
            Товары
          </Text>
          <Products>
            {products.map((product, index) => {
              const cartItem = order.products.find(
                (item) => item.nomenclatureId === product.id
              );
              if (!cartItem) return null;
              return (
                <NomenclatureProduct
                  key={product.id}
                  className="product"
                  product={product}
                  last={index === products.length - 1}
                  rightContent={
                    <div className="product-info">
                      <Badge color="blue">{cartItem.count} шт.</Badge>
                      <Text size="md" className="price">
                        {priceFormatter.format(product.cost)}
                      </Text>
                    </div>
                  }
                />
              );
            })}
          </Products>
          <UpdateOrderModal
            visible={updateVisible}
            onClose={() => setUpdateVisible(false)}
            order={order}
          />
        </>
      ) : null}
    </>
  );
};

const Value = ({ label, value }) => {
  return (
    <ValueView>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </ValueView>
  );
};

const ValueView = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }

  .label {
    margin-right: 16px;
    width: 150px;
    font-size: 14px;
    color: gray;

    @media only screen and (max-width: 500px) {
      margin-right: 0;
    }
  }

  .value {
    font-weight: 500;
    font-size: 14px;
  }
`;

const Products = styled.div`
  margin-top: 12px;

  .product-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .price {
      margin-left: 12px;
    }

    @media only screen and (max-width: 500px) {
      flex-direction: column;
      margin-top: 10px;

      .price {
        margin-top: 6px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .product {
      display: flex;
      flex-direction: column;

      .name {
        margin-top: 10px;
        margin-left: 0;
        text-align: center;
      }
    }
  }
`;
