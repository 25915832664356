import { gql } from "@apollo/client";

export const SALE_POINTS = gql`
  query SALE_POINT {
    salePoints {
      id
      address
      sbisId
      latitude
      longitude
      locality
      name
      workTimeStart
      workTimeStop
      workDays
      priceLists {
        id
        sbisId
        sbisSalePointId
        name
      }
    }
  }
`;
