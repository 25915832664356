import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Center, Group, Loader, Modal, TextInput } from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";

import { NOMENCLATURES } from "../../gqls/nomenclature";
import { IconPhoto } from "@tabler/icons-react";
import { CREATE_WEEK_PRODUCT } from "../../gqls/week-product";
import { notifications } from "@mantine/notifications";

export const CreateWeekProductModal = ({
  opened,
  onClose,
  refetch = () => {},
}) => {
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setFilterSearch(search || "");
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [search]);

  const { data, loading } = useQuery(NOMENCLATURES, {
    variables: {
      where: {
        name: {
          contains: filterSearch,
          mode: "insensitive",
        },
        externalId: { not: { equals: null } },
        isParent: { equals: null },
      },
      skip: 0,
      take: 10,
    },
  });

  const [createWeekProduct, { loading: createLoading }] = useMutation(
    CREATE_WEEK_PRODUCT,
    {
      onCompleted: () => {
        refetch();
        onClose();
        setSearch("");
        setFilterSearch("");
        setSelectedProduct(null);
      },
      onError: (err) => {
        console.error(err);
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
      },
    }
  );

  const handleCreate = () => {
    if (!selectedProduct) return;
    createWeekProduct({
      variables: { data: { nomenclatureId: selectedProduct.id } },
    });
  };

  const nomenclatures = data?.nomenclatures || [];

  return (
    <Modal
      title="Добавить товар недели"
      opened={opened}
      onClose={onClose}
      size="lg"
    >
      <TextInput
        label="Поиск товара..."
        placeholder="Введите название товара"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setSelectedProduct(null);
        }}
        mb={16}
      />
      {loading ? (
        <Center pt={36} pb={26}>
          <Loader size="sm" />
        </Center>
      ) : selectedProduct ? (
        <NomenclatureProduct
          product={selectedProduct}
          onClick={() => {
            setSelectedProduct(null);
          }}
          active
        />
      ) : (
        nomenclatures.map((item) => (
          <NomenclatureProduct
            key={item.id}
            product={item}
            onClick={() => {
              setSelectedProduct(item);
            }}
          />
        ))
      )}
      <Group justify="flex-end">
        <Button variant="outline" onClick={onClose}>
          Отмена
        </Button>
        <Button loading={createLoading} onClick={handleCreate}>
          Добавить
        </Button>
      </Group>
    </Modal>
  );
};

export const NomenclatureProduct = ({
  product,
  onClick,
  active = false,
  last,
  rightContent,
  className,
}) => {
  return (
    <Product
      onClick={onClick}
      active={active ? "active" : ""}
      last={last ? "last" : ""}
      clickable={onClick ? "clickable" : ""}
      className={className}
    >
      {product.images?.length ? (
        <img
          alt=""
          src={`/api/sbis-images?img=${product.images[0]}`}
          className="image"
        />
      ) : (
        <DefaultImage>
          <IconPhoto color="#cdcdcd" />
        </DefaultImage>
      )}
      <div className="name">{product.name}</div>
      {rightContent ? rightContent : null}
    </Product>
  );
};

const Product = styled.div`
  border: 1px solid ${(props) => (props.active ? "#228be6" : "#cdcdcd")};
  border-radius: 12px;
  padding: 16px;
  margin-bottom: ${(props) => (props.last ? "0px" : "16px")};
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.clickable
      ? `
        cursor: pointer;
        transition: 0.3s border;

        &:hover {
          border: 1px solid gray;
        }
      `
      : ""}

  .name {
    flex: 1;
    font-size: 14px;
    margin-left: 16px;
  }

  .image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    background-color: #f8f8f8;
    border-radius: 6px;
  }
`;

const DefaultImage = styled.div`
  width: 50px;
  height: 50px;
  object-fit: contain;
  background-color: #f8f8f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
