import React from "react";
import {
  Badge,
  Button,
  Center,
  Group,
  Image,
  Loader,
  Text,
} from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import { DELETE_PROMOTION, PROMOTION } from "../../gqls/promotion";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";

export const SinglePromotionPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [deletePromotion, { loading: deleting }] = useMutation(
    DELETE_PROMOTION,
    {
      onCompleted: () => {
        navigate("/promotions");
      },
      onError: (err) => {
        console.error(err);
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
      },
    }
  );

  const { data, loading } = useQuery(PROMOTION, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
  });

  const handleDelete = () => {
    if (window.confirm("Удалить акцию?")) {
      deletePromotion({ variables: { where: { id: params.id } } });
    }
  };

  const promotion = data?.promotion;

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Акция</Text>
        {promotion ? (
          promotion.active ? (
            <Badge color="green">Действует</Badge>
          ) : (
            <Badge color="red">Не действует</Badge>
          )
        ) : null}
      </Group>
      {loading ? (
        <Center h={100}>
          <Loader size="sm" />
        </Center>
      ) : promotion ? (
        <>
          <Image
            mt={16}
            w={300}
            src={`/uploads/${promotion.image}`}
            alt={promotion.image}
          />
          <Text mt={12} w="600">
            {promotion.title}
          </Text>
          <Text mt={6} c="gray" style={{ whiteSpace: "pre-line" }}>
            {promotion.description}
          </Text>
          <Text size="sm" mt={12}>
            {dayjs(promotion.createdAt).format("DD.MM.YYYY HH:mm")}
          </Text>
          <Group mt={16}>
            <Button
              onClick={() => navigate(`/promotions/${params.id}/update`)}
              variant="outline"
              size="xs"
            >
              Изменить
            </Button>
            <Button
              onClick={handleDelete}
              color="red"
              variant="outline"
              size="xs"
              loading={deleting}
            >
              Удалить
            </Button>
          </Group>
        </>
      ) : null}
    </>
  );
};
