import React, { useState } from "react";
import { Center, Group, Loader, Text } from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";

import { DELETE_WEEK_PRODUCT, WEEK_PRODUCTS } from "../../gqls/week-product";
import { NOMENCLATURES } from "../../gqls/nomenclature";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { CreateWeekProductModal, NomenclatureProduct } from "./create-modal";
import styled from "styled-components";
import { notifications } from "@mantine/notifications";

export const WeekProductsPage = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const {
    data: weekProductsData,
    loading: weekProductsLoading,
    refetch,
  } = useQuery(WEEK_PRODUCTS, {
    fetchPolicy: "network-only",
  });
  const weekProducts = weekProductsData?.weekProducts || [];

  const { data: nomenclaturesData, loading: nomenclaturesLoading } = useQuery(
    NOMENCLATURES,
    {
      skip: !weekProducts.length,
      variables: {
        where: { id: { in: weekProducts.map((item) => item.nomenclatureId) } },
      },
      fetchPolicy: "network-only",
    }
  );
  const nomenclatures = nomenclaturesData?.nomenclatures || [];

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Товары недели</Text>
        <Group
          style={{ cursor: "pointer" }}
          onClick={() => setShowCreateModal(true)}
          gap={6}
          align="center"
        >
          <IconPlus color="#228be6" size={16} />
          <Text size="sm" c="blue">
            Добавить
          </Text>
        </Group>
      </Group>
      {weekProductsLoading || nomenclaturesLoading ? (
        <Center h={100}>
          <Loader size="sm" />
        </Center>
      ) : (
        <View>
          {nomenclatures.map((item, index) => (
            <Product
              key={item.id}
              item={item}
              last={index === nomenclatures.length - 1}
              refetch={refetch}
            />
          ))}
        </View>
      )}
      <CreateWeekProductModal
        opened={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        refetch={refetch}
      />
    </>
  );
};

const Product = ({ item, last, refetch }) => {
  const [deleteWeekProduct, { loading: deleteLoading }] = useMutation(
    DELETE_WEEK_PRODUCT,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        console.error(err);
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
      },
    }
  );

  const handleDelete = () => {
    if (window.confirm("Убрать из товаров недели?")) {
      deleteWeekProduct({ variables: { where: { nomenclatureId: item.id } } });
    }
  };

  return (
    <NomenclatureProduct
      key={item.id}
      product={item}
      last={last}
      rightContent={
        deleteLoading ? (
          <Loader size="xs" />
        ) : (
          <div className="delete" onClick={handleDelete}>
            <IconTrash color="red" size={18} />
          </div>
        )
      }
    />
  );
};

const View = styled.div`
  margin-top: 16px;

  .delete {
    cursor: pointer;
    transition: 0.3s opacity;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 0.5;
    }
  }
`;
