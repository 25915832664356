import { gql } from "@apollo/client";

export const WEEK_PRODUCTS = gql`
  query WEEK_PRODUCTS {
    weekProducts {
      id
      nomenclatureId
    }
  }
`;

export const CREATE_WEEK_PRODUCT = gql`
  mutation CREATE_WEEK_PRODUCT($data: CreateWeekProduct!) {
    createWeekProduct(data: $data) {
      id
      nomenclatureId
    }
  }
`;

export const DELETE_WEEK_PRODUCT = gql`
  mutation DELETE_WEEK_PRODUCT($where: WeekProductWhereInput!) {
    deleteWeekProduct(where: $where) {
      id
      nomenclatureId
    }
  }
`;
