import { useMutation } from "@apollo/client";
import {
  Button,
  FileInput,
  Group,
  Image,
  Modal,
  Textarea,
} from "@mantine/core";
import React, { useState } from "react";
import { notifications } from "@mantine/notifications";

import { UPDATE_NOMENCLATURE } from "../../gqls/nomenclature";

export const UpdateModal = ({ item, setShowEdit, updateChildren }) => {
  const [files, setFiles] = useState(undefined);
  const [description, setDescription] = useState(item.description || "");
  const [loading, setLoading] = useState(false);

  const [updateNomenclature] = useMutation(UPDATE_NOMENCLATURE);

  const handleUpdate = async () => {
    setLoading(true);
    let customImages = undefined;
    if (files?.length) {
      for (let file of files) {
        const formData = new FormData();
        formData.append("file", file);
        const photoResponse = await fetch("/api/admin/upload", {
          method: "POST",
          body: formData,
          headers: { authorization: localStorage.getItem("accessToken") },
        })
          .then((res) => res.json())
          .catch(() => {});
        if (!photoResponse?.filename) continue;
        if (customImages) {
          customImages.push(photoResponse.filename);
        } else {
          customImages = [photoResponse.filename];
        }
      }
    }
    try {
      await updateNomenclature({
        variables: {
          where: { id: item.id },
          data: { description, customImages },
        },
      });
      await updateChildren();
      setShowEdit(false);
    } catch (err) {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
    }
    setLoading(false);
  };

  return (
    <Modal
      title="Изменить номенклатуру"
      opened
      onClose={() => setShowEdit(false)}
    >
      <Textarea
        label="Описание"
        placeholder="Введите описание"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        autosize
      />
      <FileInput
        label="Свои изображения"
        mt={16}
        placeholder="Нажмите, чтобы выбрать изображения"
        value={files}
        onChange={setFiles}
        multiple
        clearable
      />
      {!files?.length && item.customImages?.length ? (
        <Group wrap="wrap" mt={16}>
          {item.customImages.map((item) => (
            <Image src={`/uploads/${item}`} h={100} w="auto" />
          ))}
        </Group>
      ) : null}
      <Group mt={16} justify="flex-end">
        <Button variant="outline" onClick={() => setShowEdit(false)}>
          Отмена
        </Button>
        <Button loading={loading} onClick={handleUpdate}>
          Изменить
        </Button>
      </Group>
    </Modal>
  );
};
