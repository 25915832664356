import {
  ActionIcon,
  Card,
  Center,
  Container,
  Group,
  Image,
  Loader,
  NavLink,
  Text,
} from "@mantine/core";
import { Link, Navigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  IconLogout,
  IconNews,
  IconHome,
  IconWallpaper,
  IconCurrencyDollar,
  IconCategory,
  IconStar,
  IconShoppingCart,
  IconPaperBag,
} from "@tabler/icons-react";

import { useAdmin } from "../hooks/use-admin";

export const Layout = ({ children }) => {
  const { loading, admin } = useAdmin();

  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/login";
  };

  if (loading) {
    return (
      <Center h="100vh" w="100vw">
        <Loader />
      </Center>
    );
  }

  if (!admin) return <Navigate to="/login" />;

  return (
    <>
      <Header>
        <Container size="lg" className="content">
          <a href="/">
            <Image className="logo" src="/logo.png" alt="logo" />
          </a>
          <Group>
            <Text className="email" size="sm">
              {admin.email}
            </Text>
            <ActionIcon onClick={handleLogout} variant="outline" color="red">
              <IconLogout size={16} />
            </ActionIcon>
          </Group>
        </Container>
      </Header>
      <Content>
        <Container size="lg" className="content">
          <div>
            <Navbar withBorder padding={0} shadow="sm">
              <NavLink
                leftSection={<IconHome size="1rem" stroke={1.5} />}
                label="Главная"
                variant="filled"
                active={location.pathname === "/"}
                component={Link}
                to="/"
              />
              <NavLink
                leftSection={<IconWallpaper size="1rem" stroke={1.5} />}
                label="Обзоры"
                variant="filled"
                active={location.pathname === "/reviews"}
                component={Link}
                to="/reviews"
              />
              <NavLink
                leftSection={<IconNews size="1rem" stroke={1.5} />}
                label="Новости"
                variant="filled"
                active={location.pathname === "/news"}
                component={Link}
                to="/news"
              />
              <NavLink
                leftSection={<IconCurrencyDollar size="1rem" stroke={1.5} />}
                label="Акции"
                variant="filled"
                active={location.pathname === "/promotions"}
                component={Link}
                to="/promotions"
              />
              <NavLink
                leftSection={<IconCategory size="1rem" stroke={1.5} />}
                label="Категории"
                variant="filled"
                active={location.pathname === "/categories"}
                component={Link}
                to="/categories"
              />
              <NavLink
                leftSection={<IconStar size="1rem" stroke={1.5} />}
                label="Товары недели"
                variant="filled"
                active={location.pathname === "/week-products"}
                component={Link}
                to="/week-products"
              />
              <NavLink
                leftSection={<IconShoppingCart size="1rem" stroke={1.5} />}
                label="Номенклатура"
                variant="filled"
                active={location.pathname === "/nomenclatures"}
                component={Link}
                to="/nomenclatures"
              />
              <NavLink
                leftSection={<IconPaperBag size="1rem" stroke={1.5} />}
                label="Заказы"
                variant="filled"
                active={location.pathname === "/orders"}
                component={Link}
                to="/orders"
              />
            </Navbar>
          </div>
          <div className="children">
            <Card withBorder shadow="sm">
              {children}
            </Card>
          </div>
        </Container>
      </Content>
    </>
  );
};

const Header = styled.div`
  height: 60px;
  border-bottom: 1px solid #dee2e6;
  background-color: #fff;

  .content {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logo {
      height: 42px;
      object-fit: contain;
    }
  }

  @media only screen and (max-width: 450px) {
    .email {
      display: none;
    }
  }
`;

const Navbar = styled(Card)`
  background-color: #fff;
  width: 260px;

  @media only screen and (max-width: 800px) {
    width: 200px;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

const Content = styled.div`
  margin-top: 16px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: 750px) {
      flex-direction: column;
    }
  }

  .children {
    flex: 1;
    margin-left: 20px;

    @media only screen and (max-width: 750px) {
      margin-left: 0;
      margin-top: 15px;
    }
  }
`;

export const withLayout = (component) => {
  const Page = component;
  return (
    <Layout>
      <Page />
    </Layout>
  );
};
