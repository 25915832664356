import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
  Card as MCard,
  Center,
  Input,
  Space,
  Button,
  Text,
  Alert,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

import { LOGIN_ADMIN } from "../gqls/admin";

export const LoginPage = () => {
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const [loginAdmin, { loading }] = useMutation(LOGIN_ADMIN, {
    onError: (err) => {
      console.error(err);
      setError("Неправильный email или пароль");
    },
    onCompleted: (data) => {
      const accessToken = data?.loginAdmin;
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
        navigate("/");
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    if (error) setError("");
    loginAdmin({ variables: { data: { email, password } } });
  };

  return (
    <Center h="100vh" w="100vw">
      <Card shadow="md" padding="lg" radius="md" withBorder>
        <Text>Войти в панель администратора</Text>
        <Space h="md" />
        <form onSubmit={handleSubmit}>
          <Input.Wrapper label="Email" required>
            <Input name="email" type="email" required />
          </Input.Wrapper>
          <Space h="md" />
          <Input.Wrapper label="Пароль" required>
            <Input name="password" type="password" required />
          </Input.Wrapper>
          {error ? (
            <Alert mt="md" icon={<IconAlertCircle size="1rem" />} color="red">
              {error}
            </Alert>
          ) : null}
          <Space h="md" />
          <Button loading={loading} type="submit">
            Войти
          </Button>
        </form>
      </Card>
    </Center>
  );
};

const Card = styled(MCard)`
  width: 400px;

  @media only screen and (max-width: 440px) {
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
