import React from "react";
import { Button, Center, Group, Image, Loader, Text } from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import { DELETE_NEWS, SINGLE_NEWS } from "../../gqls/news";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";

export const SingleNewsPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [deleteNews, { loading: deleting }] = useMutation(DELETE_NEWS, {
    onCompleted: () => {
      navigate("/news");
    },
    onError: (err) => {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
    },
  });

  const { data, loading } = useQuery(SINGLE_NEWS, {
    fetchPolicy: "network-only",
    variables: { where: { id: params.id } },
  });

  const handleDelete = () => {
    if (window.confirm("Удалить новость?")) {
      deleteNews({ variables: { where: { id: params.id } } });
    }
  };

  const news = data?.singleNews;

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Новость</Text>
      </Group>
      {loading ? (
        <Center h={100}>
          <Loader size="sm" />
        </Center>
      ) : news ? (
        <>
          <Image
            mt={16}
            w={300}
            src={`/uploads/${news.image}`}
            alt={news.image}
          />
          <Text mt={12} w="600">
            {news.title}
          </Text>
          <Text mt={6} c="gray" style={{ whiteSpace: "pre-line" }}>
            {news.description}
          </Text>
          <Text size="sm" mt={12}>
            {dayjs(news.createdAt).format("DD.MM.YYYY HH:mm")}
          </Text>
          <Group mt={16}>
            <Button
              onClick={() => navigate(`/news/${params.id}/update`)}
              variant="outline"
              size="xs"
            >
              Изменить
            </Button>
            <Button
              onClick={handleDelete}
              color="red"
              variant="outline"
              size="xs"
              loading={deleting}
            >
              Удалить
            </Button>
          </Group>
        </>
      ) : null}
    </>
  );
};
