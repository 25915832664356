import { Button, Card, Group, Loader, Modal, Text } from "@mantine/core";
import React, { Suspense, lazy, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import { IconPencil, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import dynamicIconImports from "lucide-react/dynamicIconImports";

import {
  NOMENCLATURES,
  DELETE_NOMENCLATURE,
  UPDATE_NOMENCLATURE,
} from "../../gqls/nomenclature";

export const CategoriesPage = () => {
  const { data, loading, refetch } = useQuery(NOMENCLATURES, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        isParent: { equals: true },
        hierarchicalParent: null,
      },
      orderBy: { name: "asc" },
    },
  });

  const categories = data?.nomenclatures || [];

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Категории</Text>
      </Group>
      {loading ? (
        <Loader mt={12} size="sm" />
      ) : (
        <Content>
          {categories.map((item, index) => (
            <Category
              key={item.id}
              category={item}
              last={index === categories.length - 1}
              refetch={refetch}
            />
          ))}
        </Content>
      )}
    </>
  );
};

const Category = ({ category, last, refetch }) => {
  const [updateVisible, setUpdateVisible] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(category.categoryIcon);

  const [deleteCategory, { loading: deleteLoading }] = useMutation(
    DELETE_NOMENCLATURE,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        console.error(err);
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
      },
    }
  );

  const [updateCategory, { loading: updateLoading }] = useMutation(
    UPDATE_NOMENCLATURE,
    {
      onCompleted: () => {
        setUpdateVisible(false);
        notifications.show({
          color: "green",
          title: "Категория изменена",
        });
      },
      onError: (err) => {
        console.error(err);
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
      },
    }
  );

  const handleDelete = () => {
    if (window.confirm("Удалить категорию?")) {
      deleteCategory({ variables: { where: { id: category.id } } });
    }
  };

  const handleUpdate = () => {
    if (selectedIcon) {
      updateCategory({
        variables: {
          where: { id: category.id },
          data: { categoryIcon: selectedIcon },
        },
      });
    }
  };

  return (
    <>
      <Card withBorder shadow="sm" mb={last ? 0 : 12} p="sm">
        <Group align="center" justify="space-between">
          <Group gap="xs">
            {category.categoryIcon ? (
              <Icon name={category.categoryIcon} size={14} />
            ) : null}
            <Text size="sm">{category.name}</Text>
          </Group>
          <Group gap="xs">
            <div
              onClick={() => {
                setUpdateVisible(true);
              }}
              className="icon-btn"
            >
              <IconPencil size={20} color="#89CFF0" />
            </div>
            {deleteLoading ? (
              <Loader size={16} color="#fa5252" />
            ) : (
              <div onClick={handleDelete} className="icon-btn">
                <IconX size={20} color="#fa5252" />
              </div>
            )}
          </Group>
        </Group>
      </Card>
      <Modal
        title="Выбрать иконку"
        opened={updateVisible}
        onClose={() => setUpdateVisible(false)}
      >
        {updateVisible ? (
          <Icons>
            <Group>
              {Object.keys(dynamicIconImports).map((key) => (
                <IconContainer
                  key={key}
                  selected={selectedIcon === key ? "selected" : undefined}
                  onClick={() => setSelectedIcon(key)}
                >
                  <Icon name={key} selected={selectedIcon === key} />
                </IconContainer>
              ))}
            </Group>
          </Icons>
        ) : null}
        <Group justify="flex-end">
          <Button onClick={() => setUpdateVisible(false)} variant="outline">
            Отмена
          </Button>
          <Button loading={updateLoading} onClick={handleUpdate}>
            Сохранить
          </Button>
        </Group>
      </Modal>
    </>
  );
};

const Icon = ({ name, selected, ...props }) => {
  const LucideIcon = lazy(dynamicIconImports[name]);

  const fallback = (
    <div style={{ background: "#ddd", width: 24, height: 24 }} />
  );

  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  );
};

const Content = styled.div`
  margin-top: 16px;
  max-width: 500px;

  .icon-btn {
    cursor: pointer;
    transition: 0.3s opacity;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  transition: 0.3s opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  ${(props) => (props.selected ? "border: 2px solid #228be6;" : "")}

  &:hover {
    opacity: 0.8;
  }
`;

const Icons = styled.div`
  max-height: 500px;
  overflow: auto;
  margin-bottom: 16px;
  border: 1px solid silver;
  border-radius: 8px;
  padding: 16px;
`;
