import { gql } from "@apollo/client";

const NEWS_FRAGMENT = gql`
  fragment NewsReturn on News {
    id
    createdAt
    updatedAt
    title
    description
    image
    products
  }
`;

export const SINGLE_NEWS = gql`
  ${NEWS_FRAGMENT}
  query SINGLE_NEWS($where: NewsWhereInput!) {
    singleNews(where: $where) {
      ...NewsReturn
    }
  }
`;

export const MANY_NEWS = gql`
  ${NEWS_FRAGMENT}
  query MANY_NEWS($skip: Int, $take: Int) {
    manyNews(skip: $skip, take: $take) {
      ...NewsReturn
    }
    manyNewsCount
  }
`;

export const CREATE_NEWS = gql`
  ${NEWS_FRAGMENT}
  mutation CREATE_NEWS($data: CreateNewsInput!) {
    createNews(data: $data) {
      ...NewsReturn
    }
  }
`;

export const UPDATE_NEWS = gql`
  ${NEWS_FRAGMENT}
  mutation UPDATE_NEWS($where: NewsWhereInput!, $data: UpdateNewsInput!) {
    updateNews(where: $where, data: $data) {
      ...NewsReturn
    }
  }
`;

export const DELETE_NEWS = gql`
  ${NEWS_FRAGMENT}
  mutation DELETE_NEWS($where: NewsWhereInput!) {
    deleteNews(where: $where) {
      ...NewsReturn
    }
  }
`;
