import { gql } from "@apollo/client";

const PROMOTION_FRAGMENT = gql`
  fragment PromotionReturn on Promotion {
    id
    createdAt
    updatedAt
    title
    description
    image
    products
    active
  }
`;

export const PROMOTION = gql`
  ${PROMOTION_FRAGMENT}
  query PROMOTION($where: PromotionWhereInput!) {
    promotion(where: $where) {
      ...PromotionReturn
    }
  }
`;

export const PROMOTIONS = gql`
  ${PROMOTION_FRAGMENT}
  query PROMOTIONS($skip: Int, $take: Int, $where: PromotionsWhereInput) {
    promotions(skip: $skip, take: $take, where: $where) {
      ...PromotionReturn
    }
    promotionsCount
  }
`;

export const CREATE_PROMOTION = gql`
  ${PROMOTION_FRAGMENT}
  mutation CREATE_PROMOTION($data: CreatePromotionInput!) {
    createPromotion(data: $data) {
      ...PromotionReturn
    }
  }
`;

export const UPDATE_PROMOTION = gql`
  ${PROMOTION_FRAGMENT}
  mutation UPDATE_PROMOTION(
    $where: PromotionWhereInput!
    $data: UpdatePromotionInput!
  ) {
    updatePromotion(where: $where, data: $data) {
      ...PromotionReturn
    }
  }
`;

export const DELETE_PROMOTION = gql`
  ${PROMOTION_FRAGMENT}
  mutation DELETE_PROMOTION($where: PromotionWhereInput!) {
    deletePromotion(where: $where) {
      ...PromotionReturn
    }
  }
`;
