import { gql } from "@apollo/client";

const NOMENCLATURE_FRAGMENT = gql`
  fragment NomenclatureReturn on Nomenclature {
    id
    createdAt
    updatedAt
    article
    attributes
    balance
    cost
    description
    descriptionSimple
    externalId
    hierarchicalId
    hierarchicalParent
    sbisId
    images
    customImages
    indexNumber
    isKit
    isParent
    modifiers
    name
    nomNumber
    published
    shortCode
    unit
    categoryIcon
  }
`;

export const NOMENCLATURE = gql`
  ${NOMENCLATURE_FRAGMENT}
  query NOMENCLATURE($where: NomenclaturesWhereInput!) {
    nomenclature(where: $where) {
      ...NomenclatureReturn
    }
  }
`;

export const NOMENCLATURES = gql`
  ${NOMENCLATURE_FRAGMENT}
  query NOMENCLATURES(
    $where: NomenclaturesWhereInput!
    $skip: Int
    $take: Int
    $orderBy: NomenclaturesOrderByInput
  ) {
    nomenclatures(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
      ...NomenclatureReturn
    }
  }
`;

export const DELETE_NOMENCLATURE = gql`
  ${NOMENCLATURE_FRAGMENT}
  mutation DELETE_NOMENCLATURE($where: NomenclatureWhereInput!) {
    deleteNomenclature(where: $where) {
      ...NomenclatureReturn
    }
  }
`;

export const UPDATE_NOMENCLATURE = gql`
  ${NOMENCLATURE_FRAGMENT}
  mutation UPDATE_NOMENCLATURE(
    $where: NomenclatureWhereInput!
    $data: NomenclatureUpdateInput!
  ) {
    updateNomenclature(where: $where, data: $data) {
      ...NomenclatureReturn
    }
  }
`;
