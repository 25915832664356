import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  FileInput,
  Group,
  Loader,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Image } from "lucide-react";

import { CREATE_REVIEW } from "../../gqls/review";
import { NOMENCLATURES } from "../../gqls/nomenclature";
import { IconCheck } from "@tabler/icons-react";

export const CreateReviewPage = () => {
  const [name, setName] = useState("");
  const [video, setVideo] = useState("");
  const [searchNomenclature, setSearchNomenclature] = useState("");
  const [nomenclature, setNomenclature] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [
    nomenclaturesQuery,
    { data: nomenclaturesData, loading: nomenclaturesLoading },
  ] = useLazyQuery(NOMENCLATURES);

  const [createReview] = useMutation(CREATE_REVIEW);
  const navigate = useNavigate();

  useEffect(() => {
    let timeout = setTimeout(() => {
      nomenclaturesQuery({
        variables: {
          skip: 0,
          take: 10,
          where: {
            name: {
              contains: searchNomenclature,
              mode: "insensitive",
            },
          },
        },
      });
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [nomenclaturesQuery, searchNomenclature]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nomenclature) {
      notifications.show({ color: "yellow", title: "Выберите товар" });
      return;
    }
    if (!file) {
      notifications.show({ color: "yellow", title: "Выберите изображение" });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const photoResponse = await fetch("/api/admin/upload", {
        method: "POST",
        body: formData,
        headers: { authorization: localStorage.getItem("accessToken") },
      }).then((res) => res.json());
      if (!photoResponse?.filename) {
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
        setLoading(false);
        return;
      }
      const image = photoResponse.filename;
      await createReview({
        variables: {
          data: {
            name,
            nomenclatureIndexNumber: nomenclature.indexNumber,
            video,
            image,
          },
        },
      });
      navigate("/reviews");
      setLoading(false);
    } catch (err) {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
      setLoading(false);
    }
  };

  const nomenclatures = nomenclaturesData?.nomenclatures || [];

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Добавить обзор</Text>
      </Group>
      <Form onSubmit={handleSubmit}>
        <TextInput
          type="text"
          placeholder="Введите название..."
          label="Название"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextInput
          type="text"
          placeholder="Вставьте ссылку на видео (YouTube)..."
          label="Ссылка на YouTube"
          required
          mt={16}
          value={video}
          onChange={(e) => setVideo(e.target.value)}
        />
        <FileInput
          label="Изображение"
          placeholder="Нажмите, чтобы выбрать изображение"
          required
          mt={16}
          value={file}
          onChange={setFile}
        />
        <TextInput
          type="text"
          placeholder="Поиск товара по названию..."
          label="Товар"
          required
          mt={16}
          value={searchNomenclature}
          onChange={(e) => {
            setNomenclature(undefined);
            setSearchNomenclature(e.target.value);
          }}
        />
        {nomenclaturesLoading ? (
          <Group mt={16}>
            <Loader size="sm" />
          </Group>
        ) : nomenclatures.length ? (
          <>
            <Space h={16} />
            {nomenclatures
              .filter((item) => {
                if (nomenclature) {
                  if (nomenclature?.id === item?.id) {
                    return true;
                  } else {
                    return false;
                  }
                }
                return true;
              })
              .map((item, index) => (
                <Card
                  withBorder
                  shadow="sm"
                  p="sm"
                  key={item.id}
                  mb={index === nomenclatures.length - 1 ? 0 : 10}
                  onClick={() => {
                    if (nomenclature?.id === item?.id) {
                      setNomenclature(undefined);
                    } else {
                      setNomenclature(item);
                    }
                  }}
                  className="nomenclature-card"
                >
                  <Group align="center" justify="space-between">
                    <Group align="center" gap="xs">
                      {item.images?.length ? (
                        <img
                          className="image"
                          src={`/api/sbis-images?img=${item.images[0]}`}
                          alt={item.images[0]}
                        />
                      ) : (
                        <div className="no-image">
                          <Image color="#cdcdcd" />
                        </div>
                      )}
                      <Text style={{ flex: 1 }} size="xs">
                        {item.name}
                      </Text>
                    </Group>
                    {nomenclature?.id === item?.id ? (
                      <IconCheck color="#228be6" size={18} />
                    ) : null}
                  </Group>
                </Card>
              ))}
          </>
        ) : null}
        <Button loading={loading} type="submit" mt={16}>
          Добавить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.form`
  max-width: 500px;
  margin-top: 8px;

  .image {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  .no-image {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border: 1px solid #cdcdcd;
  }

  .nomenclature-card {
    cursor: pointer;

    &:hover {
      border: 1px solid #228be6;
    }
  }
`;
