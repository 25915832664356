import { gql } from "@apollo/client";

const BANNER_FRAGMENT = gql`
  fragment BannerReturn on Banner {
    id
    title
    subtitle
    type
    background
  }
`;

export const BANNERS = gql`
  ${BANNER_FRAGMENT}
  query BANNERS($where: BannersWhereInput) {
    banners(where: $where) {
      ...BannerReturn
    }
  }
`;

export const UPDATE_BANNER = gql`
  ${BANNER_FRAGMENT}
  mutation UPDATE_BANNER(
    $data: UpdateBannerInput!
    $where: UpdateBannerWhereInput!
  ) {
    updateBanner(data: $data, where: $where) {
      ...BannerReturn
    }
  }
`;
