import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  FileInput,
  Group,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { CREATE_NEWS } from "../../gqls/news";

export const CreateNewsPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [createNews] = useMutation(CREATE_NEWS);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      notifications.show({ color: "yellow", title: "Выберите изображение" });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const photoResponse = await fetch("/api/admin/upload", {
        method: "POST",
        body: formData,
        headers: { authorization: localStorage.getItem("accessToken") },
      }).then((res) => res.json());
      if (!photoResponse?.filename) {
        notifications.show({
          color: "red",
          title: "Произошла ошибка, повторите еще раз",
        });
        setLoading(false);
        return;
      }
      const image = photoResponse.filename;
      await createNews({
        variables: { data: { title, description, image, products: [] } },
      });
      navigate("/news");
      setLoading(false);
    } catch (err) {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Добавить новость</Text>
      </Group>
      <Form onSubmit={handleSubmit}>
        <TextInput
          type="text"
          placeholder="Введите заголовок..."
          label="Заголовок"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Textarea
          label="Описание"
          placeholder="Введите описание..."
          mt={16}
          autosize
          minRows={6}
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FileInput
          label="Изображение"
          placeholder="Нажмите, чтобы выбрать изображение"
          required
          mt={16}
          value={file}
          onChange={setFile}
        />
        <Button loading={loading} type="submit" mt={16}>
          Добавить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.form`
  max-width: 500px;
  margin-top: 8px;
`;
