import { createBrowserRouter } from "react-router-dom";

import { HomePage } from "./pages/home";
import { ReviewsPage } from "./pages/reviews";
import { CreateReviewPage } from "./pages/reviews/create";
import { NewsPage } from "./pages/news";
import { LoginPage } from "./pages/login";
import { withLayout } from "./components/layout";
import { CreateNewsPage } from "./pages/news/create";
import { SingleNewsPage } from "./pages/news/single";
import { UpdateNewsPage } from "./pages/news/update";
import { PromotionsPage } from "./pages/promotions";
import { CreatePromotionPage } from "./pages/promotions/create";
import { UpdatePromotionPage } from "./pages/promotions/update";
import { SinglePromotionPage } from "./pages/promotions/single";
import { CategoriesPage } from "./pages/categories";
import { UpdateReviewPage } from "./pages/reviews/update";
import { WeekProductsPage } from "./pages/week-products";
import { NomenclaturesPage } from "./pages/nomenclatures";
import { OrdersPage } from "./pages/orders";
import { OrderPage } from "./pages/orders/single";

export const router = createBrowserRouter([
  {
    path: "/",
    element: withLayout(HomePage),
  },
  {
    path: "/reviews",
    element: withLayout(ReviewsPage),
  },
  {
    path: "/reviews/create",
    element: withLayout(CreateReviewPage),
  },
  {
    path: "/reviews/:id/update",
    element: withLayout(UpdateReviewPage),
  },
  {
    path: "/news",
    element: withLayout(NewsPage),
  },
  {
    path: "/news/create",
    element: withLayout(CreateNewsPage),
  },
  {
    path: "/news/:id/update",
    element: withLayout(UpdateNewsPage),
  },
  {
    path: "/news/:id",
    element: withLayout(SingleNewsPage),
  },
  {
    path: "/promotions",
    element: withLayout(PromotionsPage),
  },
  {
    path: "/promotions/create",
    element: withLayout(CreatePromotionPage),
  },
  {
    path: "/promotions/:id/update",
    element: withLayout(UpdatePromotionPage),
  },
  {
    path: "/promotions/:id",
    element: withLayout(SinglePromotionPage),
  },
  {
    path: "/categories",
    element: withLayout(CategoriesPage),
  },
  {
    path: "/week-products",
    element: withLayout(WeekProductsPage),
  },
  {
    path: "/nomenclatures",
    element: withLayout(NomenclaturesPage),
  },
  {
    path: "/orders",
    element: withLayout(OrdersPage),
  },
  {
    path: "/orders/:id",
    element: withLayout(OrderPage),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);
