import { useMutation } from "@apollo/client";
import { Button, Group, Modal, Select } from "@mantine/core";
import React, { useState } from "react";
import { notifications } from "@mantine/notifications";

import { UPDATE_ORDER } from "../../gqls/order";

export const UpdateOrderModal = ({ visible, onClose, order }) => {
  const [status, setStatus] = useState(order.status);
  const [paymentStatus, setPaymentStatus] = useState(order.paymentStatus);

  const [updateOrder, { loading }] = useMutation(UPDATE_ORDER, {
    onCompleted: () => {
      onClose();
    },
    onError: (err) => {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
    },
  });

  const handleUpdate = () => {
    updateOrder({
      variables: { where: { id: order.id }, data: { status, paymentStatus } },
    });
  };

  return (
    <Modal title="Изменить статус" opened={visible} onClose={onClose}>
      <Select
        label="Статус"
        data={[
          { value: "new", label: "Новый" },
          { value: "processing", label: "В обработке" },
          { value: "done", label: "Выполнен" },
          { value: "cancelled", label: "Отменен" },
        ]}
        value={status}
        onChange={(v) => setStatus(v ? v : undefined)}
      />
      <Select
        label="Статус оплаты"
        data={[
          { value: "paid", label: "Оплачен" },
          { value: "not-paid", label: "Не оплачен" },
        ]}
        mt={16}
        value={paymentStatus}
        onChange={(v) => setPaymentStatus(v ? v : undefined)}
      />
      <Group mt={20} justify="flex-end">
        <Button variant="outline">Отмена</Button>
        <Button onClick={handleUpdate} loading={loading}>
          Изменить
        </Button>
      </Group>
    </Modal>
  );
};
