import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Button,
  Card,
  FileInput,
  Group,
  Loader,
  Space,
  Text,
  TextInput,
  Image as MantineImage,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { Image } from "lucide-react";

import { REVIEW, UPDATE_REVIEW } from "../../gqls/review";
import { NOMENCLATURES } from "../../gqls/nomenclature";
import { IconCheck } from "@tabler/icons-react";

export const UpdateReviewPage = () => {
  const [name, setName] = useState("");
  const [video, setVideo] = useState("");
  const [searchNomenclature, setSearchNomenclature] = useState("");
  const [nomenclature, setNomenclature] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [defaultImage, setDefaultImage] = useState(undefined);
  const [defaultNomenclatureIndexNumber, setDefaultNomenclatureIndexNumber] =
    useState(undefined);

  const [
    nomenclaturesQuery,
    { data: nomenclaturesData, loading: nomenclaturesLoading },
  ] = useLazyQuery(NOMENCLATURES, {
    onCompleted: (res) => {
      const nomenclature = res?.nomenclatures?.[0];
      if (nomenclature?.indexNumber === defaultNomenclatureIndexNumber) {
        setNomenclature(nomenclature);
      }
    },
  });

  const [updateReview] = useMutation(UPDATE_REVIEW);
  const navigate = useNavigate();
  const params = useParams();

  const { loading: reviewLoading } = useQuery(REVIEW, {
    variables: { where: { id: params.id } },
    onCompleted: (res) => {
      const review = res?.review;
      if (review) {
        setName(review.name);
        setVideo(review.video);
        setDefaultImage(review.image);
        setDefaultNomenclatureIndexNumber(review.nomenclatureIndexNumber);
      }
    },
  });

  useEffect(() => {
    let timeout = setTimeout(() => {
      nomenclaturesQuery({
        variables: {
          skip: 0,
          take: 10,
          where: searchNomenclature
            ? {
                name: {
                  contains: searchNomenclature,
                  mode: "insensitive",
                },
              }
            : {
                indexNumber: {
                  equals: parseInt(defaultNomenclatureIndexNumber),
                },
              },
        },
      });
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [nomenclaturesQuery, searchNomenclature, defaultNomenclatureIndexNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nomenclature) {
      notifications.show({ color: "yellow", title: "Выберите товар" });
      return;
    }
    setLoading(true);
    try {
      let image;
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const photoResponse = await fetch("/api/admin/upload", {
          method: "POST",
          body: formData,
          headers: { authorization: localStorage.getItem("accessToken") },
        }).then((res) => res.json());
        if (!photoResponse?.filename) {
          notifications.show({
            color: "red",
            title: "Произошла ошибка, повторите еще раз",
          });
          setLoading(false);
          return;
        }
        image = photoResponse.filename;
      }
      await updateReview({
        variables: {
          data: {
            name,
            nomenclatureIndexNumber: nomenclature.indexNumber,
            video,
            image,
          },
          where: { id: params.id },
        },
      });
      navigate("/reviews");
      setLoading(false);
    } catch (err) {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
      setLoading(false);
    }
  };

  const nomenclatures = nomenclaturesData?.nomenclatures || [];

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Изменить обзор</Text>
      </Group>
      {reviewLoading ? (
        <Loader size="sm" mt={16} />
      ) : (
        <Form onSubmit={handleSubmit}>
          <TextInput
            type="text"
            placeholder="Введите название..."
            label="Название"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            type="text"
            placeholder="Вставьте ссылку на видео (YouTube)..."
            label="Ссылка на YouTube"
            required
            mt={16}
            value={video}
            onChange={(e) => setVideo(e.target.value)}
          />
          <FileInput
            label="Изображение"
            placeholder="Нажмите, чтобы выбрать изображение"
            required
            mt={16}
            value={file}
            onChange={setFile}
          />
          {!file && defaultImage ? (
            <MantineImage
              src={`/uploads/${defaultImage}`}
              alt={defaultImage}
              mt={16}
              w={200}
            />
          ) : null}
          <TextInput
            type="text"
            placeholder="Поиск товара по названию..."
            label="Товар"
            mt={16}
            value={searchNomenclature}
            onChange={(e) => {
              setNomenclature(undefined);
              setSearchNomenclature(e.target.value);
            }}
          />
          {nomenclaturesLoading ? (
            <Group mt={16}>
              <Loader size="sm" />
            </Group>
          ) : nomenclatures.length ? (
            <>
              <Space h={16} />
              {nomenclatures
                .filter((item) => {
                  if (nomenclature) {
                    if (nomenclature?.id === item?.id) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                  return true;
                })
                .map((item, index) => (
                  <Card
                    withBorder
                    shadow="sm"
                    p="sm"
                    key={item.id}
                    mb={index === nomenclatures.length - 1 ? 0 : 10}
                    onClick={() => {
                      if (nomenclature?.id === item?.id) {
                        setNomenclature(undefined);
                      } else {
                        setNomenclature(item);
                      }
                    }}
                    className="nomenclature-card"
                  >
                    <Group align="center" justify="space-between">
                      <Group align="center" gap="xs">
                        {item.images?.length ? (
                          <img
                            className="image"
                            src={`/api/sbis-images?img=${item.images[0]}`}
                            alt={item.images[0]}
                          />
                        ) : (
                          <div className="no-image">
                            <Image color="#cdcdcd" />
                          </div>
                        )}
                        <Text style={{ flex: 1 }} size="xs">
                          {item.name}
                        </Text>
                      </Group>
                      {nomenclature?.id === item?.id ? (
                        <IconCheck color="#228be6" size={18} />
                      ) : null}
                    </Group>
                  </Card>
                ))}
            </>
          ) : null}
          <Button
            loading={loading}
            disabled={nomenclaturesLoading || reviewLoading}
            type="submit"
            mt={16}
          >
            Изменить
          </Button>
        </Form>
      )}
    </>
  );
};

const Form = styled.form`
  max-width: 500px;
  margin-top: 8px;

  .image {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }

  .no-image {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
    border: 1px solid #cdcdcd;
  }

  .nomenclature-card {
    cursor: pointer;

    &:hover {
      border: 1px solid #228be6;
    }
  }
`;
