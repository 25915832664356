import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Loader, Text, TextInput } from "@mantine/core";
import { useLazyQuery, useQuery } from "@apollo/client";

import { NOMENCLATURES } from "../../gqls/nomenclature";
import {
  IconChevronDown,
  IconChevronRight,
  IconEdit,
  IconPhoto,
} from "@tabler/icons-react";
import { UpdateModal } from "./update-modal";

export const NomenclaturesPage = () => {
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  useEffect(() => {
    let timeout = setTimeout(() => {
      setFilterSearch(search);
    }, 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [search]);

  const { data, loading } = useQuery(NOMENCLATURES, {
    fetchPolicy: "network-only",
    variables: {
      where: filterSearch
        ? {
            name: {
              contains: filterSearch,
              mode: "insensitive",
            },
          }
        : {
            isParent: { equals: true },
            hierarchicalParent: null,
          },
      orderBy: { name: "asc" },
    },
  });

  const nomenclatures = data?.nomenclatures || [];

  return (
    <>
      <Text fw="600">Номенклатура</Text>
      <TextInput
        placeholder="Поиск..."
        mt={8}
        maw={500}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {loading ? (
        <Loader mt={12} size="sm" />
      ) : (
        <Content>
          {nomenclatures.map((item) => {
            if (item.isParent) {
              return <ParentComponent key={item.id} item={item} />;
            }
            return (
              <ChildrenComponent
                key={item.id}
                item={item}
                style={{ marginLeft: 0 }}
              />
            );
          })}
        </Content>
      )}
    </>
  );
};

const ParentComponent = ({ item, style }) => {
  const [children, setChildren] = useState([]);

  const [getNomenclatures, { loading }] = useLazyQuery(NOMENCLATURES);

  const [show, setShow] = useState(false);

  const updateChildren = useCallback(() => {
    getNomenclatures({
      variables: {
        where: { hierarchicalParent: { equals: item.hierarchicalId } },
      },
    }).then((res) => {
      setChildren(res?.data?.nomenclatures || []);
    });
  }, [getNomenclatures, item.hierarchicalId]);

  useEffect(() => {
    if (show) {
      updateChildren();
    } else {
      setChildren([]);
    }
  }, [show, updateChildren]);

  return (
    <Parent style={style}>
      <div className="top">
        <div
          className="chevron"
          onClick={() => {
            setShow((prev) => !prev);
          }}
        >
          {show ? (
            <IconChevronDown size={18} color="gray" />
          ) : (
            <IconChevronRight size={18} color="gray" />
          )}
        </div>
        <div className="name">{item.name}</div>
      </div>
      {show ? (
        loading ? (
          <Loader mt={12} size="sm" />
        ) : (
          <div className="children">
            {children.map((item) => {
              if (item.isParent) {
                return <ParentComponent key={item.id} item={item} />;
              }
              return (
                <ChildrenComponent
                  key={item.id}
                  item={item}
                  updateChildren={updateChildren}
                />
              );
            })}
          </div>
        )
      ) : null}
    </Parent>
  );
};

const ChildrenComponent = ({ item, style, updateChildren }) => {
  const [showEdit, setShowEdit] = useState(false);
  return (
    <Children style={style}>
      {item.customImages?.length ? (
        <img
          alt=""
          src={`/uploads/${item.customImages[0]}`}
          className="image"
        />
      ) : item.images?.length ? (
        <img
          alt=""
          src={`/api/sbis-images?img=${item.images[0]}`}
          className="image"
        />
      ) : (
        <DefaultImage>
          <IconPhoto color="#cdcdcd" />
        </DefaultImage>
      )}
      <div className="name">{item.name}</div>
      <div className="balance">{item.balance ? item.balance : "0"} шт.</div>
      <div className="edit" onClick={() => setShowEdit(true)}>
        <IconEdit size={20} color="#228be6" />
      </div>
      {showEdit ? (
        <UpdateModal
          item={item}
          setShowEdit={setShowEdit}
          updateChildren={updateChildren}
        />
      ) : null}
    </Children>
  );
};

const Content = styled.div`
  margin-top: 10px;
`;

const Parent = styled.div`
  padding: 6px 0;

  &:last-child {
    padding-bottom: 0;
  }

  .top {
    display: flex;
    flex-direction: row;
    align-items: center;

    .chevron {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .name {
      flex: 1;
      margin-left: 8px;
      font-size: 14px;
    }
  }

  .children {
    margin-left: 16px;
    margin-top: 6px;
  }
`;

const Children = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  padding: 6px 0;
  flex: 1;

  &:last-child {
    padding-bottom: 0;
  }

  .name {
    flex: 1;
    font-size: 14px;
    margin-left: 6px;
  }

  .balance {
    font-size: 14px;
    color: gray;
  }

  .edit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
  }

  .image {
    width: 40px;
    height: 40px;
    object-fit: contain;
    background-color: #f8f8f8;
    border-radius: 6px;
  }
`;

const DefaultImage = styled.div`
  width: 40px;
  height: 40px;
  object-fit: contain;
  background-color: #f8f8f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
