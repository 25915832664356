import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  Checkbox,
  FileInput,
  Group,
  Image,
  LoadingOverlay,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import { PROMOTION, UPDATE_PROMOTION } from "../../gqls/promotion";

export const UpdatePromotionPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(undefined);
  const [defaultImage, setDefaultImage] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);

  const [updatePromotion] = useMutation(UPDATE_PROMOTION);
  const navigate = useNavigate();
  const params = useParams();

  const { loading: getting } = useQuery(PROMOTION, {
    variables: { where: { id: params.id } },
    onCompleted: (data) => {
      const promotion = data?.promotion;
      if (promotion) {
        setTitle(promotion.title || "");
        setDescription(promotion.description || "");
        setActive(promotion.active || false);
        if (promotion.image) setDefaultImage(promotion.image);
      }
    },
    onError: (err) => {
      console.error(err);
      notifications.show({
        color: "red",
        title: "Произошла ошибка, повторите еще раз",
      });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let image;
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const photoResponse = await fetch("/api/admin/upload", {
          method: "POST",
          body: formData,
          headers: { authorization: localStorage.getItem("accessToken") },
        }).then((res) => res.json());
        if (!photoResponse?.filename) {
          notifications.show({
            color: "red",
            title: "Произошла ошибка, повторите еще раз",
          });
          setLoading(false);
          return;
        }
        image = photoResponse.filename;
      }
      await updatePromotion({
        variables: {
          where: { id: params.id },
          data: { title, description, image, products: [], active },
        },
      });
      navigate(`/promotions/${params.id}`);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Group align="center" justify="space-between">
        <Text fw="600">Изменить акцию</Text>
      </Group>
      <Form onSubmit={handleSubmit}>
        <LoadingOverlay
          visible={getting}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <TextInput
          type="text"
          placeholder="Введите заголовок..."
          label="Заголовок"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Textarea
          label="Описание"
          placeholder="Введите описание..."
          mt={16}
          autosize
          minRows={6}
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FileInput
          label="Изображение"
          placeholder="Нажмите, чтобы выбрать изображение"
          required
          mt={16}
          value={file}
          onChange={setFile}
        />
        {!file && defaultImage ? (
          <Image
            src={`/uploads/${defaultImage}`}
            alt={defaultImage}
            mt={16}
            width={200}
          />
        ) : null}
        <Checkbox
          checked={active}
          onChange={(e) => setActive(e.target.checked)}
          mt={16}
          label="Действующая акция"
        />
        <Button loading={loading} type="submit" mt={16}>
          Изменить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.form`
  max-width: 500px;
  margin-top: 8px;
  position: relative;
`;
